<template>
    <div id="app" :class="{ 'rtl-content-direction': rtlDirection }">
        <router-view/>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "App",
    components: {},
    computed: {
        ...mapGetters("main", {
            rtlDirection: 'rtlDirection',
        }),
    //     ...mapState("main", {
    //         system_labels: 'system_labels',
    //     }),
    },
    // mounted() {
    //     let output = {}
    //     this.system_labels.forEach( item => {
    //         let lang_key = ''
    //         for(const key in item) {
    //             if (!output[key]) output[key] = {}
    //             if (key === 'Expression') {
    //                 lang_key = item[key]
    //             }
    //             else {
    //                 output[key][lang_key] = item[key]
    //             }
    //         }
    //     })
    //     console.log(output);
    // },
};
</script>

<style lang="scss">
@import '@/styles/main';

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;

    &.rtl-content-direction {
        direction: rtl;
        text-align: right;
    }
}
</style>
