import data from '/data.json'
import languages from '/languages.json'
import system_labels from '/system-labels.json'
import $localStorage from "@/middleware/localStorage";

export default {
	activeBrandLabel: '',
	selectedBrandLabel: '',
	selectedBrand: '',
	searchIds: [],
	printList: [],
	menuActive: false,
	current_language: 'EN',
	localStorageLang: $localStorage.getItem('language'),
	languages,
	system_labels,
	...data
}