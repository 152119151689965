import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import main from "./main";

export default new Vuex.Store({
	strict: process.env.NODE_ENV !== 'production',
	modules: {
		main,
	}
});