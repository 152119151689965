<template>
    <router-link :to="to" @click.native="$emit('click')">
        <button :class="`btn btn-${variant}`">
            <slot>{{ label }}</slot>
        </button>
    </router-link>
</template>

<script>
export default {
    name: "LinkButton",
    props: {
        to: {
            type: [String, Object],
            default: ''
        },
        variant: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
    },
}
</script>

<style lang="scss" scoped>

a {
    text-decoration: none;
    display: inline-block;
    height: max-content;
}

</style>
