import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

let routes = [
	{
		path: '/',
		name: 'dashboard',
		component: () => import('@/views/Dashboard.vue'),
		children: [
			{
				path: '/search-result',
				name: 'search-result',
				components: { dashboard: () => import('@/views/Dashboard/SearchResult.vue') }
				
			},
			{
				path: '/product/:id',
				name: 'product',
				components: { dashboard: () => import('@/views/Dashboard/Product.vue') }
			},
			{
				path: '/product/:id/category/:catId',
				name: 'category',
				components: { dashboard: () => import('@/views/Dashboard/Product.vue') }
			},
			{
				path: 'print-list',
				name: 'print-list',
				components: { dashboard: () => import('@/views/Dashboard/PrintList.vue') }
			},
		]
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router;