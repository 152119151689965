export default {
	getItem( name ) {
		return JSON.parse( localStorage.getItem( name ) );
	},
	setItem( name, data ) {
		return localStorage.setItem( name, JSON.stringify( data ) );
	},
	removeItem( name ) {
		localStorage.removeItem( name );
	},
	clear() {
		localStorage.clear();
	}
}