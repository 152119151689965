import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import {
    BootstrapVue,
    IconsPlugin
} from 'bootstrap-vue'

Vue.prototype.$eventBus = new Vue();

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

const params = new URLSearchParams(window.location.search)
if (params.has('version')) {
    let version = params.get('version');
    let savedVersion = localStorage.getItem('version');
    if (version != Number(savedVersion)) {
        localStorage.clear();
        localStorage.setItem('version', version)
        location.reload()
    }
}

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueFormulate from '@braid/vue-formulate'
Vue.use(VueFormulate)

import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)

import LinkButton from "@/components/LinkButton.vue";
Vue.component('link-button', LinkButton);

import localStorage from '@/middleware/localStorage';
Vue.prototype.$localStorage = localStorage;

import {
    getImage
} from '@/middleware/getImage';
Vue.prototype.$image = getImage;

let brandsColors = {
    edscha: '#009b54',
    sesam: '#004990'
};
Vue.prototype.$getBrandColor = (selectedBrandLabel) => {
    let output = {
        '--brand-color': brandsColors.edscha
    }
    if (selectedBrandLabel && selectedBrandLabel.toLowerCase().includes('sesam')) {
        output['--brand-color'] = brandsColors.sesam;
    }
    return output;
};

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')