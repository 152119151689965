import $localStorage from "@/middleware/localStorage";

export default {
	updateSelectedBrand: ( state, brand ) => {
		state.selectedBrand = brand;
		if (
			state.labels &&
			state.brands[brand] &&
			state.brands[brand].label &&
			state.labels[state.current_language] &&
			state.labels[state.current_language][state.brands[brand].label]
		) {
			state.selectedBrandLabel = state.labels[state.current_language][state.brands[brand].label]
		}
	},

	updateActiveBrand: ( state, brand ) => {
		if (
			state.labels &&
			state.brands[brand] &&
			state.brands[brand].label &&
			state.labels[state.current_language] &&
			state.labels[state.current_language][state.brands[brand].label]
		) {
			state.activeBrandLabel = state.labels[state.current_language][state.brands[brand].label]
		}
	},

	updateMenuState: ( state, payload ) => state.menuActive = payload,

	updateSearchIds: ( state, payload ) => state.searchIds = payload,

	updatePrintList: ( state, payload ) => state.printList = payload,

	clearPrintList: ( state ) => state.printList = [],

	setLanguage: ( state, payload ) => {
		state.current_language = payload
		state.localStorageLang = payload
		$localStorage.setItem("language", payload);
	},
}