export default {
    labelGetter: state => {
        return ( key, hint ) => {
            if (state.labels[state.current_language] && state.labels[state.current_language][key]) {
                return state.labels[state.current_language][key]
            }
            else {
                console.warn(`Not found label for key "${key}" on ${state.current_language} for the ${hint}. Please add label to the data labels`);
                return key
            }
        }
    },
    sysLabelGetter: state => {
        return key => {
            if ( state.system_labels[state.current_language] && state.system_labels[state.current_language][key] ) {
                return state.system_labels[state.current_language][key]
            }
            else {
                console.warn(`Not found label for key "${key}" on ${state.current_language}. Please add label to the system labels`);
                return key
            }
        }
    },
    brandVariant: state => {
        if (state.selectedBrandLabel.toLowerCase().includes("sesam")) return "btn-sesam";
        return "btn-edscha";
    },
    rtlDirection( state ) {
        if ( ['AR'].includes(state.current_language) ) return true
        return false
    },
}