export function getImage( name, settings ) {
	try {
		let extension = '';
		if ( settings && settings.extension ) extension = `.${settings.extension}`;
		let folder = '';
		if ( settings && settings.folder ) {
			folder = settings.folder.split('/').join('/') + '/';
		}
		return require(`@/assets/${folder}${name}${extension}`);
	} catch (e) {
		if ( !settings || ( settings && !settings.alt ) ) {
			return require('@/assets/no-image.png');
		}
	}
}